import React from 'react';
import Button from '../../components/Button';

import ScreenwritingIMG from '../../assets/arm.png'
import YoutubeSEOIMG from '../../assets/seo.png'
import ThumbnailIMG from '../../assets/thumbnail.gif'
import EditingIMG from '../../assets/editing.gif'
import VoiceoverIMG from '../../assets/voiceover.gif'

const ScreenWriting = () => {
    return (
        <div className="screenwriting card">
            <h3>Scriptwriting</h3>
            <p className="text">A YouTube script is the written framework for your video. It’s a specific dialog, visual cues, and call-to-actions (CTAs) to hit so that you stay on-topic while also keeping viewers engaged. Here is the structure I usually follow when writing a YouTube script:</p>
            <ol>
                <li>Hook</li>
                <li>Re-engage</li>
                <li>Setup / Body</li>
                <li>Climax Step</li>
                <li>Message / Call to action</li>
                <li>Outro / Wrap Up</li>
            </ol>
            <img src={ScreenwritingIMG} alt="" />
        </div>
    )
}

const YouTubeSEO = () => {
    return (
        <div className="youtubeSEO card">
            <div className='content'>
            <h3>Youtube SEO</h3>
            <p className="text">YouTube Video SEO (Search Engine Optimization) means optimizing your YouTube videos and channel. </p>
            <p className='text'>This ranks your videos higher in YouTube search results and to increases your visibility and organic traffic on the platform.</p>
            </div>
            <img src={YoutubeSEOIMG} alt="" />

        </div>
    )
}

const Thumbnail = () => {
    return (
        <div className="thumbnail card">
            <h3>Thumbnail</h3>
            <p className="text">An eye-catching thumbnail that accurately represents your video content and encourages viewers to click on your video.</p>
            <img src={ThumbnailIMG} alt="" />
        </div>
    )
}

const Editing = () => {
    return (
        <div className="editing card">
            <h3>Video Editing</h3>
            <p className="text">High-quality, informative, engaging video content that meets the needs and interests of your target audience.</p>
            <img src={EditingIMG} alt="" />
        </div>
    )
}

const Voiceover = () => {
    return (
        <div className="voiceover card">
            <div className="content">
            <h3>Voiceover (narration)</h3>

            <p className="text">Voiceover means a person speaking (but not seen) during a video — often describing, highlighting, explaining, or providing additional context to what a viewer sees.</p>
            <p className="text">Narration is a specific type of voice over that describes all of the on-screen action, often telling a story based on what’s happening.</p>
            </div>
            <img src={VoiceoverIMG} alt="" />
        </div>
    )
}

const services = {
    screenwriting : 'screenwriting',
    youtubeSEO : 'youtubeSEO',
    thumbnail : 'thumbnail',
    editing : 'editing',
    voiceover : 'voiceover',
}
const Services = () => {
    const [selectedService, setSelectedService] = React.useState(services.screenwriting);
    return (
        <div className="services">
            <h2>My Services 🔧</h2>
            <div className="desktop">

            <div className="content">
                <div className="btns"  >
                <Button className='color1' label="Scriptwriting" outlined={selectedService !== services.screenwriting} onClick={() => setSelectedService(services.screenwriting)} />
                <Button className='color2' label="Youtube SEO" outlined={selectedService !== services.youtubeSEO} onClick={() => setSelectedService(services.youtubeSEO)} />
                    <Button className='color4'  label="Thumbnail" outlined={selectedService !== services.thumbnail} onClick={() => setSelectedService(services.thumbnail)} />
                    <Button className='color3' label="Video Editing" outlined={selectedService !== services.editing} onClick={() => setSelectedService(services.editing)} />
                    <Button className='color8' label="Voiceover" outlined={selectedService !== services.voiceover} onClick={() => setSelectedService(services.voiceover)} />
                </div>
                <div className="service">
                    {selectedService === services.screenwriting && <ScreenWriting />}
                    {selectedService === services.youtubeSEO && <YouTubeSEO />}
                    {selectedService === services.thumbnail && <Thumbnail />}
                    {selectedService === services.editing && <Editing />}
                    {selectedService === services.voiceover && <Voiceover />}
                </div>
            </div>
            </div>
            <div className="mobile">
                <div className="content">
                <div className="service">
                    <ScreenWriting />
                    <YouTubeSEO />
                    <Thumbnail />
                    <Editing />
                    <Voiceover />
                </div>
                </div>
            </div>
        </div>
        
        
            
    )

}

export default Services;