import React from "react";
import Button from "./Button";
import { redirectToWhatsapp } from "../store/cta";
type CallToActionProps = {
    header: string,
    btn: string
}
const CallToAction = ({header,btn}: CallToActionProps) => {

    return (
        <div className='cta' id='contact' >
            <h2>{header}</h2>
            <Button label={btn} onClick={redirectToWhatsapp} />
        </div>
    );
}

export default CallToAction;