import React from "react";

import Popup from "../../components/Popup";

const CallToAction1 = () => {

    return (
        <div className='cta'>
            <h2>If you’re in doubts - let’s hop on a video call! ☎️</h2>
            <Popup />
        </div>
    );
}
    
export default CallToAction1;