import React from 'react';
import { useState } from 'react';

import ArrowIcon from "../assets/Arrow";

type AccordionProps = {
    key: number;
    question: string;
    answer: string;
    color: string;
}
const Accordion: React.FC<AccordionProps> = ({ question, answer, color }) => {
    const [expanded, setExpanded] = useState(false);

    const toggleAccordion = () => {
        setExpanded(!expanded);
    };

    return (
        <div 
            onClick={toggleAccordion}
            onKeyDown={toggleAccordion}
            tabIndex={0}
            role='button'
            className={`faq-item ${expanded ? 'expanded' : ''}`}>
            <div className="question" >
                <p className="body4">{question}</p>
                <div className="arrow">

                <ArrowIcon isOpen={expanded} color={color} />
                </div>
            </div>
            <div className={`answer ${expanded ? 'expanded' : ''}`}>
                <p className="body5">{answer}</p>
            </div>
        </div>
    );
};

export default Accordion;