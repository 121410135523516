import React, { useEffect } from 'react'
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from 'react-router-dom'
import HomePage from './pages/landing/Container'
import NotFoundPage from './pages/NotFoundPage'
import './styles/main.scss'
import Layout from './components/Layout'
import Script from './pages/script/Script'
import Video from './pages/video/Video'
import SEO from './pages/seo/SEO'
import { ROUTES } from './utility/functions'
import PrivacyPolicy from './pages/PrivacyPolicy'

const UnderConstruction: React.FC = () => {
    return <h2>Under Construction</h2>
}

type ScrollToTopProps = {
    children: React.ReactNode
}

const ScrollToTop = (props: ScrollToTopProps) => {
    const location = useLocation();
    useEffect(() => {
        document.body.scrollTo(0, 0); 
    }, [location]);

    return <>{props.children}</>
};



const RootComponent: React.FC = () => {
    return (
        <Router>
            <ScrollToTop>
            <Routes>
                <Route path="/" element={<Layout />}>
                    <Route path="*" element={<Navigate to={ROUTES.HOMEPAGE_ROUTE} />} />
                    <Route path={ROUTES.HOMEPAGE_ROUTE} element={<HomePage />} />
                    <Route path={ROUTES.SCRIPT} element={<Script />} />
                    <Route path={ROUTES.VIDEO} element={<Video />} />
                    <Route path={ROUTES.YOUTUBE_MANAGEMENT} element={<SEO />} />
                    <Route path={ROUTES.PRIVACY_POLICY} element={<PrivacyPolicy />} />
                </Route>
                </Routes>
            </ScrollToTop>
        </Router>
    )
}

export {
    ROUTES
}

export default RootComponent
