import React from 'react';
import { Outlet } from 'react-router-dom';
import Navigation from './Nav';
import Footer from './Footer';
// import ScrollButton from '../assets/scroll.svg';

const Layout: React.FC = () => {
    const scrollToView = (e: any) => {
        e.preventDefault();
        // Scroll to a certain element
        document.getElementById("contact")?.scrollIntoView({
            behavior: "smooth",
        });
    };
    return (
        <div className='layout'>
            <Navigation />
            <main>
                <Outlet />
            </main>
            <Footer />
            <button className='scroll-button' onClick={scrollToView}>
                {/* <img src={ScrollButton} alt='Scroll to top' /> */}
            </button>
        </div>
    );
};

export default Layout;