/**
 * This function can be used anywhere in the app to greet the user
 * @param userName The user's first name
 * @returns A kind greeting message
 */
export const sayHello = (userName: string): string => {
    return 'Welcome ' + userName + '!'
}

export const getDesktopBP = (): number => {
    return 1150;
}

export const ROUTES = {
    HOMEPAGE_ROUTE: '/',
    NOT_FOUND_ROUTE: '*',
    SCRIPT: '/script',
    VIDEO: '/video',
    YOUTUBE_MANAGEMENT: '/youtube-management',
    PARTNERS: '/partners',
    PRIVACY_POLICY: '/privacy-policy'
}
