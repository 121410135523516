import React, { useEffect } from 'react';

import avatar from '../../assets/reviews/avatar.png';
import avatar1 from '../../assets/reviews/avatar1.png';
import avatar2 from '../../assets/reviews/avatar2.png';
import avatar3 from '../../assets/reviews/avatar3.png';
import avatar4 from '../../assets/reviews/avatar4.png';
import avatar5 from '../../assets/reviews/avatar5.png';

import star from '../../assets/reviews/star.svg';

import Review1 from '../../assets/reviews/review (1).png';
import Review2 from '../../assets/reviews/review (2).png';
import Review3 from '../../assets/reviews/review (3).png';
import Review4 from '../../assets/reviews/review (4).png';
import Review5 from '../../assets/reviews/review (5).png';
import Review6 from '../../assets/reviews/review (6).png';
import Review7 from '../../assets/reviews/review (7).png';
import Review8 from '../../assets/reviews/review (8).png';

const reviews = [
    {
        name: 'Ivee15',
        location: 'Australia',
        when: '9 months ago',
        review: 'marakvrely is a master of his craft. Smashes out high quality videos with only a script and voicover to work with.All in good time as well.Managed to complete four videos for me in under a week.Epic speed. 10/10 recommend. Looking forward to sending him more projects.',
        avatar: avatar
    },
    {
        name: 'jeanettegower',
        location: 'Australia',
        when: '9 months ago',
        review: 'Mark is an excellent communicator, and made the process seem simple. As a newbie to this, had questions and revisions, but he handled it withprofessionalism and friendliness. Best of all | received a product which will serve me well. Thank you Mark.',
        avatar: avatar1
    },
    {
        name: 'brianmurphy899',
        location: 'United States',
        when: '10 months ago',
        review: 'Mark was very easy to work with. He had a very quick response time to my request and open to all creative input. He then delivered a high quality script on time and as promised. | would not hesitate to work with Mark again, highly recommended',
        avatar: avatar2
    },
    {
        name: 'richard89',
        location: 'United States',
        when: '9 months ago',
         review: 'AWESOME VIDEO EDITING 👍👍👍',
        avatar: avatar3
    },
    {
        name: 'sheisn0tperfect',
        location: 'United States',
        when: '8 months ago',
        review: 'Great job and great communication! He is very professional.. | can tell he love to help.. | will let my familys and friends know about this..Definitely will work with you again..Thank you so much for your work Give you 5 stars!!!',
        avatar: avatar4
    },
    {
        name: 'anthonydoty',
        location: 'United State',
        when: '7 months ago',
        review: 'As always, Mark and his team do a fantastic job with video creation. | highly recommend it to anyone who needs a video created or even video scripts. Thisis my third purchase for video creation content. Try 1 you will be surprised.',
        avatar: avatar5
    }
]

const Reviews = () => {

    useEffect(() => {
        const scrollers = document.querySelectorAll('.scroller');

        if (!window.matchMedia('(prefers-reduced-motion: reduce)').matches) {
            addAnimation();
        }
        // clean up previous items
        return () => {
            scrollers.forEach((scroller) => {
                // @ts-ignore
                scroller.removeAttribute('data-animated');
                const scrollerInner = scroller.querySelector('.scroller__inner');
                const scrollerContent = Array.from(scrollerInner!.children);

                scrollerContent.forEach((item) => {
                    if (item.getAttribute('aria-hidden')) {
                        scrollerInner!.removeChild(item);
                    }
                });
            });
        };
        function addAnimation() {
            scrollers.forEach((scroller) => {
                // @ts-ignore
                scroller.setAttribute('data-animated', true);
                const scrollerInner = scroller.querySelector('.scroller__inner');
                const scrollerContent = Array.from(scrollerInner!.children);

                scrollerContent.forEach((item) => {
                    const duplicatedItem = item.cloneNode(true);
                    // @ts-ignore
                    duplicatedItem.setAttribute('aria-hidden', true);
                    scrollerInner!.appendChild(duplicatedItem);
                });
            });
        }
    }, []);
    return (
        <div className="reviews">
            <div className="desktop">

            <div className="scroller" style={{ transform: `rotate(-8deg)` }} data-direction="left" data-speed="slow">
                <div className="row scroller__inner">
                    <img src={Review8} alt="" />
                    <img src={Review7} alt="" />
                    <img src={Review6} alt="" />
                    <img src={Review5} alt="" />
                </div>
            </div>
            <div className="scroller" style={{ transform: `rotate(-8deg)` }} data-direction="right" data-speed="slow">
                <div className="row scroller__inner">
                    <img src={Review1} alt="" />
                    <img src={Review2} alt="" />
                    <img src={Review3} alt="" />
                    <img src={Review4} alt="" />
                </div>
            </div>
            </div>
            <div className="mobile">
                {reviews.map((review, index) => (
                    <div key={index} className="review">
                        <div className="review__avatar">
                            <img src={review.avatar} alt="" />
                        </div>
                        <div className="review__content">
                            <h4>{review.name}</h4>
                            <p>{review.location}</p>
                            <div className="review__rating">
                                <div className="stars">
                                    <img src={star} alt="" className="star" />
                                    <img src={star} alt="" className="star" />
                                    <img src={star} alt="" className="star" />
                                    <img src={star} alt='' className="star" />
                                    <img src={star} alt='' className="star" />
                                </div>
                                <p>5</p>
                                <p className="ago">{review.when}</p>
                            </div>
                            <p className='text'>{review.review}</p>
                            
                        </div>
                    </div>
                        )
                        )}
                                
            </div>

        </div>
    )
}

export default Reviews;