import React, { useEffect } from 'react';
import Button from './Button';

import { getCalApi } from "@calcom/embed-react";

type Props = {
    calLink?: string
    label?: string
}

const Popup = ({ calLink, label }: Props) => {
    useEffect(() => {
        try {
            (async function () {
                const cal = await getCalApi();
                cal("ui", {
                    "hideEventTypeDetails": false, "layout": "month_view"
                });
            })();
        } catch (e) {
            console.error(e)
            console.log("Cal failed to load")
        }
    }, [])
    return (
        <Button
            data-cal-namespace=""
            data-cal-link={calLink || "markavrely/15min"}
            onClick={() => {
                try {
                    // @ts-ignore
                    gtag_report_conversion()
                } catch (e) {
                    console.error(e)
                }
            }
            }
            data-cal-config='{"layout":"month_view"}'
            label={label || "Get Free Consultation"}
            color="primary" />
    )
}

Popup.defaultProps = {
    calLink: "markavrely/15min",
    label: "Get Free Consultation"
}

export default Popup;