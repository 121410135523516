import { clients_url, price_cards_url, scripts_url, testimonials_url, videos_url } from "./const";

export type Brand = {
    id: number;
    name: string;
    img: string;
    subs: string;
    url: string;
    color: string;
};
export type ScriptCardProps = {
    id: number,
    title: string | null,
    header: string | null,
    price: string,
    body: string,
    priority: number,
    medal_color: string,
    items: {
        id: number,
        title: string | null,
        bullets: {
            id: number,
            title: string
        }[]
    }[]
}
const fetchData = async (url: string, defaultValue: any): Promise<any> => {
    try {
        const response = await fetch(url);
        if (!response.ok) {
            return defaultValue;
        }
        const data = await response.json();
        return data;
    } catch (error) {
        return defaultValue;
    }
};

const getBrands = async (): Promise<Brand[] | null> => {
    return fetchData(clients_url, null);
};

const getTestimonials = async () => {
    return fetchData(testimonials_url, null);
};

const getScripts = async () => {
    return fetchData(scripts_url, null);
};

const getVideo = async () => {
    return fetchData(videos_url, null);
};

const getPriceCards = async(defaultValue: ScriptCardProps[]) => {
        return fetchData(price_cards_url, defaultValue);
}

export { getBrands, getTestimonials, getScripts, getVideo, getPriceCards };
