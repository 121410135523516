import React from 'react';

import Mark2 from '../../assets/mark2.png';
import Back from '../../assets/back.png';
import Frame from '../../assets/frame.png';
import { ROUTES } from '../../RootComponent';

const Portfolio = () => {
    
    return (
        <div id='portfolio' className="portfolio">
            <h2>Portfolio 💼</h2>
            <div className="portfolio-content">
                <div className="video">
                    <img src={Mark2} alt="" className="mark" />
                    <img src={Back} alt="" className="back" />
                    <img src={Frame} alt="" className="frame" />
                </div>
                <div className="examples">
                    <div className="example">
                        <h5>Scriptwriting</h5>
                        <p>A unique script tailored to your needs. <a href={ROUTES.SCRIPT}>Click here</a> to see examples of scripts per each YouTube category.</p>
                    </div>
                    <div className="example">
                        <h5>Video Production</h5>
                        <p>Yes, we can cover the whole video production process. Examples <a href={ROUTES.VIDEO}>here</a>.</p>
                    </div>
                    <div className="example">
                        <h5>YouTube Management</h5>
                        <p>Wanna make your YouTube a side hustle without any hassle? Together with my team I will handle your channel. Invest, relax and enjoy your income growth. <a href={ROUTES.YOUTUBE_MANAGEMENT}>Click to learn more</a>.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Portfolio;