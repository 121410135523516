import React, { useEffect, useState } from 'react'
import { getVideo } from '../../api/getItems'
import './video.scss'
import PlayIMG from '../../assets/play.png'
import Pagination from '../../components/Pagination'
import { Roadmap } from '../script/Script'
import RoadmapIMG from '../../assets/roadmap_videos.png'
import CallToAction from '../../components/CTA'

type VideoCardProps = {
    id: number,
    title: string,
    url: string,
    img: string,
    subs: string

}

const defaultVideoCards: VideoCardProps[] = [
    {
        "id": 1,
        "title": "Top 10 Most Badass Quicksilver Scenes",
        "url": "https://www.youtube.com/watch?v=inKZP5LpHFs",
        "img": "https://markavrely.pythonanywhere.com/logos/videos/Rectangle_29.png",
        "subs": "6.7 million views"
    },
    {
        "id": 2,
        "title": "Top 10 Badass Pablo Escobar Moments from Narcos VOLUME-2",
        "url": "https://www.youtube.com/watch?v=uvAiTTv8C7E&ab_channel=TheTVRegent",
        "img": "https://markavrely.pythonanywhere.com/logos/videos/maxresdefault_1.jpg",
        "subs": "3.6 million views"
    },
    {
        "id": 3,
        "title": "10 AMAZING Discoveries in Egypt That SCARE Scientists",
        "url": "https://www.youtube.com/watch?v=eZLT_02zrNc&ab_channel=Crunch",
        "img": "https://markavrely.pythonanywhere.com/logos/videos/maxresdefault_2.jpg",
        "subs": "3.6 million views"
    },
    {
        "id": 4,
        "title": "15 Most Expensive Dogs in the World",
        "url": "https://www.youtube.com/watch?v=pzEjNy9ulnU&ab_channel=4EverGreen",
        "img": "https://markavrely.pythonanywhere.com/logos/videos/imageForEntry9-XeD.webp",
        "subs": "15 million views"
    },
    {
        "id": 5,
        "title": "His Babysitter Wanted To CLAP (Movie Recap)",
        "url": "https://www.youtube.com/watch?v=EKdF38d_9Qk&t=14s&ab_channel=MinuteMovies",
        "img": "https://markavrely.pythonanywhere.com/logos/videos/maxresdefault_3.jpg",
        "subs": "4.8 million views"
    },
    {
        "id": 6,
        "title": "Man Gets Invisibility Powers, So He CLAPS Her (Movie Recap)",
        "url": "https://www.youtube.com/watch?v=s-fsnrV9oI8&t=4s&ab_channel=MinuteMovies",
        "img": "https://markavrely.pythonanywhere.com/logos/videos/maxresdefault_4.jpg",
        "subs": "2.2 million views"
    },
    {
        "id": 7,
        "title": "His Life Is Stuck On Loop, So He Speedruns It (Movie Recap)",
        "url": "https://www.youtube.com/watch?v=HOKo9_g-uPg&t=5s&ab_channel=MinuteMovies",
        "img": "https://markavrely.pythonanywhere.com/logos/videos/maxresdefault_5.jpg",
        "subs": "2 million views"
    }
]

const steps: { header: string, body: string }[] = [
    {
        header: "1. Define Your Goals and Audience",
        body: "Before starting to write, I define what we want to achieve with your video and who your target audience is."
    },
    {
        header: "2. Research Your Topic",
        body: "Gather all necessary information on the topic you’re going to discuss. This involves reading articles, watching other videos, and writing down important points and statistics."
    },
    {
        header: "3. Write the Script",
        body: "I write the script in a way that it’s easy to understand and engaging. I also make sure to include a call to action at the end of the video."
    },
    {
        header: "4. Create a Storyboard",
        body: "I create a storyboard to visualize the video. This is a crucial step as it helps to understand how the video will look and feel."
    },
    {
        header: "5. Record the Voiceover",
        body: "I record the voiceover and make sure it’s clear and engaging. I also make sure to include a call to action at the end of the video."
    },
    {
        header: "6. Edit the Video",
        body: "I edit the video to make it look professional and engaging. This involves adding music, sound effects, and visuals."
    },
    {
        header: "7. Publish the Video",
        body: "I publish the video on your YouTube channel and make sure it’s optimized for search engines."
    },
    {
        header: "8. Promote the Video",
        body: "I promote the video on social media and other platforms to increase its reach and engagement."
    },
    {
        header: "9. Analyze the Results",
        body: "I analyze the results of the video to see how it performed and what can be improved in the future."
    }
]



const VideoCard = ({ title, url, img, subs }: VideoCardProps) => {
    return (
        <div className="video-card">
            <a href={url}>
            <div className="video-card__img">
                <img src={img} alt={title} className='cover' />
                <img src={PlayIMG} alt="" className="play" />
                </div>
            </a>
            <div className="video-card__content">
                <h3>{title}</h3>
                <p>{subs}</p>
            </div>
        </div>
    )
}

const ITEMS_PER_PAGE = 6;

const Video = () => {
    const [cards, setCards] = useState<VideoCardProps[]>(defaultVideoCards)
    const [currentPage, setCurrentPage] = useState<number>(0);
    useEffect(() => {
        getVideo().then((data) => {
            if (data) {
                setCards(data)
            }
        })
    }, [])

    // Calculate the index of the first and last item on the current page
    const indexOfLastItem = (currentPage+1) * ITEMS_PER_PAGE;
    const indexOfFirstItem = indexOfLastItem - ITEMS_PER_PAGE;

    // Get the current items based on the index
    const currentItems = cards.slice(indexOfFirstItem, indexOfLastItem);

    // Change the current page
    const paginate = (pageNumber: number) => {
        setCurrentPage(pageNumber);
    };

    return (
        <div className="video" id='video'>
            <h1>Top notch videos created by a dedicated team 👑</h1>
            <div className="videos">

            <Pagination
                itemsPerPage={ITEMS_PER_PAGE}
                totalItems={cards.length}
                    onPageChange={paginate}
                    id='video'
            >
                
                {currentItems.map((c) => <VideoCard key={c.id} {...c} />)}
            </Pagination>
            </div>
            <Roadmap img={RoadmapIMG} steps={steps} header='Step-by-step process of creating a video 👠' />
            <CallToAction header='Wanna see more videos? CLICK HERE!' btn='Contact me' />
        </div>
    );
}

export default Video;