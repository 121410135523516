import React from "react";
import Initial from "./Initial";
import "./style.scss";
import Brands from "./Brands";
import WhyMe from "./WhyMe";
import Portfolio from "./Portfolio";
import CallToAction1 from "./CTA1";
import Testimonials from "./Testimonials";
import CallToAction2 from "./CTA2";
import FAQ from "./FAQ";
import Reviews from "./Reviews";
import Services from "./Services";

const Landing = () => {
    return (
        <div className="landing" >
            <Initial />
            <Brands />
            <WhyMe />
            <Portfolio />
            <CallToAction1 />
            <Testimonials />
            <Services />
            <FAQ />
            <Reviews />
            <CallToAction2 />
        </div>
    );
}
    
export default Landing;