import React from 'react'

type CheckmarkProps = {
    color: string;
    markColor?: string;
}
// <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
//     <circle cx="12" cy="12" r="12" fill="#8760BC" />
//     <path d="M7 11.5L10.5 16L16.5 9" stroke="white" />
// </svg>


const Checkmark = ({ color, markColor = 'white' }: CheckmarkProps) => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="12" cy="12" r="12" fill={color} />
            <path d="M7 11.5L10.5 16L16.5 9" stroke={markColor} />
        </svg>
    )
}
export default Checkmark