import React, { useEffect, useState } from 'react';

import { Brand, getBrands } from '../../api/getItems';

const brandsDemo: Brand[] = [
    {
        "id": 1,
        "name": "Alux.com",
        "img": "https://markavrely.pythonanywhere.com/logos/logos/channels4_profile_3.jpg",
        "subs": "4.45 million",
        "url": "https://www.youtube.com/@alux",
        "color": "FFB6D4"
    },
    {
        "id": 2,
        "name": "4 Ever Green",
        "img": "https://markavrely.pythonanywhere.com/logos/logos/channels4_profile_2.jpg",
        "subs": "3.14 million",
        "url": "https://www.youtube.com/@4EverGreenYT",
        "color": "54CEC9"
    },
    {
        "id": 3,
        "name": "Jack Neel",
        "img": "https://markavrely.pythonanywhere.com/logos/logos/channels4_profile_1.jpg",
        "subs": "3.07 million",
        "url": "https://www.youtube.com/c/JackNeel",
        "color": "FFEA55"
    },
    {
        "id": 4,
        "name": "Minute Movies",
        "img": "https://markavrely.pythonanywhere.com/logos/logos/Ellipse_asdf4.png",
        "subs": "1.1 million",
        "url": "https://www.youtube.com/@MinuteMovies1",
        "color": "8760BC"
    },
    {
        "id": 5,
        "name": "Akimbo",
        "img": "https://markavrely.pythonanywhere.com/logos/logos/unnamed.jpg",
        "subs": "608000",
        "url": "https://www.youtube.com/@Akimbo-",
        "color": "5A61FF"
    },
    {
        "id": 6,
        "name": "Top 5 Best",
        "img": "https://markavrely.pythonanywhere.com/logos/logos/unnamed_1.jpg",
        "subs": "5.47 million",
        "url": "https://www.youtube.com/c/Top5best",
        "color": "8FEBFF"
    },
    {
        "id": 7,
        "name": "Unpopular Notes",
        "img": "https://markavrely.pythonanywhere.com/logos/logos/Untitled_1.png",
        "subs": "171000",
        "url": "https://www.youtube.com/@UnpopularNotes",
        "color": "FFB6D4"
    },
    {
        "id": 8,
        "name": "Ultimate Martial",
        "img": "https://markavrely.pythonanywhere.com/logos/logos/channels4_profile_4.jpg",
        "subs": "130000",
        "url": "https://www.youtube.com/@UltimateMartial",
        "color": "483E3E"
    },
    {
        "id": 9,
        "name": "Mr. History",
        "img": "https://markavrely.pythonanywhere.com/logos/logos/Untitled_5.png",
        "subs": "369000",
        "url": "https://www.youtube.com/@Mr.HistoryWithAlex",
        "color": "CCB0B0"
    },
    {
        "id": 10,
        "name": "Tuna No Crust",
        "img": "https://markavrely.pythonanywhere.com/logos/logos/unnamed_2.jpg",
        "subs": "108000",
        "url": "https://www.youtube.com/@TunaNoCrustOfficial",
        "color": "5E4D4D"
    },
    {
        "id": 11,
        "name": "TLP",
        "img": "https://markavrely.pythonanywhere.com/logos/logos/channels4_profile_5.jpg",
        "subs": "203000",
        "url": "https://www.youtube.com/@TrendLoopsNetwork",
        "color": "5A61FF"
    },
    {
        "id": 12,
        "name": "Altcoin Buzz",
        "img": "https://markavrely.pythonanywhere.com/logos/logos/Untitled_6.png",
        "subs": "408000",
        "url": "https://www.youtube.com/@AltcoinBuzz",
        "color": "8FEBFF"
    },
    {
        "id": 13,
        "name": "Factnomenal",
        "img": "https://markavrely.pythonanywhere.com/logos/logos/channels4_profile_6.jpg",
        "subs": "820000",
        "url": "https://www.youtube.com/@Factnomenal",
        "color": "5A61FF"
    },
    {
        "id": 14,
        "name": "The Tesla Space",
        "img": "https://markavrely.pythonanywhere.com/logos/logos/Untitled_7.png",
        "subs": "409000",
        "url": "https://www.youtube.com/@TheTeslaSpace",
        "color": "E55C5C"
    }
]

const Brands = () => {

    const [brands, setBrands] = useState<Brand[]>(brandsDemo);

    useEffect(() => {
            getBrands().then((data) => data&&setBrands(data));
    }, []);

    useEffect(() => {
        const scrollers = document.querySelectorAll('.scroller');

        if (!window.matchMedia('(prefers-reduced-motion: reduce)').matches) {
            addAnimation();
        }
        // clean up previous items
        return () => {
            scrollers.forEach((scroller) => {
                // @ts-ignore
                scroller.removeAttribute('data-animated');
                const scrollerInner = scroller.querySelector('.scroller__inner');
                const scrollerContent = Array.from(scrollerInner!.children);

                scrollerContent.forEach((item) => {
                    if (item.getAttribute('aria-hidden')) {
                        scrollerInner!.removeChild(item);
                    }
                });
            });
        };
        function addAnimation() {
            scrollers.forEach((scroller) => {
                // @ts-ignore
                scroller.setAttribute('data-animated', true);
                const scrollerInner = scroller.querySelector('.scroller__inner');
                const scrollerContent = Array.from(scrollerInner!.children);

                scrollerContent.forEach((item) => {
                    const duplicatedItem = item.cloneNode(true);
                    // @ts-ignore
                    duplicatedItem.setAttribute('aria-hidden', true);
                    scrollerInner!.appendChild(duplicatedItem);
                });
            });
        }
    }, [brands]);

    return (
        <div>
            <div className='brands' >
                <h2>Trusted by the best youtubers with +10b <br />
                    of subs worldwide. 🏆</h2>
                <div className="brand-list">
                    {brands && brands.map((brand) => (
                        <div className="brand" key={brand.id}>
                            <a href={brand.url}>
                                <div className='brand-card' style={{ 'backgroundColor': '#' + brand.color }}>
                                    <img src={brand.img} alt="" />
                                    <div className="text">
                                        <h4>{brand.name}</h4>
                                        <p>{brand.subs} subscribers</p>
                                    </div>
                                </div>
                            </a>
                        </div>
                    ))}
                </div>
                <div className="brand-slider scroller" style={{ transform: `rotate(-8deg)` }} data-direction="left" data-speed="slow">



                    <div className="row scroller__inner">
                        {brands.filter((_, index) => index % 2 === 0).map((brand) => (
                            <div className="brand" key={brand.id}>
                                <a href={brand.url}>
                                    <div className='brand-card' style={{'backgroundColor': '#'+brand.color}}>
                                        <img src={brand.img} alt="" />
                                        <div className="text">
                                            <h4>{brand.name}</h4>
                                            <p>{brand.subs} subscribers</p>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        ))}
                    </div>
                </div>
                <div className="brand-slider scroller" style={{ transform: `rotate(-8deg)` }} data-direction="right" data-speed="slow">

                    <div className="row scroller__inner">
                        {brands.filter((_, index) => index % 2 !== 0).map((brand) => (
                            <div className="brand" key={brand.id}>
                                <a href={brand.url}>
                                    <div className='brand-card' style={{ 'backgroundColor': '#' + brand.color }}>
                                        <img src={brand.img} alt="" />
                                        <div className="text">
                                            <h4>{brand.name}</h4>
                                            <p>{brand.subs} subscribers</p>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        ))}
                    </div>

                </div>
            </div>
        </div>
    );
    // const [position, setPosition] = useState(0);

    // useEffect(() => {
    //     const interval = setInterval(() => {
    //         setPosition((prevPosition) => (prevPosition + 0.01) % (brands.length/4));
    //     }, 5); // Adjust the interval speed as needed

    //     return () => clearInterval(interval);
    // }, []);

    // return (

    // );
}

export default Brands;