import React from 'react';
import Logo from '../assets/logo.png';
import LogoVerdix from '../assets/logoVerdix.svg'

import instagram from '../assets/footer/instagram.png';
import telegram from '../assets/footer/telegram.png';
import whatsapp from '../assets/footer/whatsapp.png';
import linkedin from '../assets/footer/linkedin.png';
import { redirectToWhatsapp } from '../store/cta';

const Footer: React.FC = () => {
    return (
        <footer>
            <div className="content">

            <div className="brand">
                <img src={Logo} alt="" className='logo' />
                <div className="social">
                    <a href="https://www.instagram.com/imark.avrely?igsh=MTlkYTdoeHpueTJnbw%3D%3D&utm_source=qr">
                        <img src={instagram} alt="" />
                    </a>
                    <a href="https://t.me/imarkavrelyy">
                        <img src={telegram} alt="" />   
                    </a>
                    <a href="#" onClick={redirectToWhatsapp}>
                        <img src={whatsapp} alt="" />
                    </a>
                    <a href="https://www.linkedin.com/in/mark-avrely">
                        <img src={linkedin} alt="" />
                    </a>
                </div>
            </div>
                
                <div className="verdix">
                    <a href="https://verdix.io">
                    <p>made by</p>
                    <img src={LogoVerdix} alt="" className="verdix" />
                    </a>
                </div>
            <div className="footer-links">
                <p>©ALL RIGHTS RESERVED</p>
                <a href="/privacy-policy">Privacy Policy</a>
            </div>
            </div>
        </footer>
    );
}

export default Footer;