import React from 'react';

import EditSVG from '../../assets/edit.svg';
import YoutubeSVG from '../../assets/youtube.svg';
import ZapSVG from '../../assets/zap.svg';
import ReadPNG from '../../assets/read.png';
import TimePNG from '../../assets/time.png';
import WinnerPNG from '../../assets/winner.png';

const WhyMe = () => {
    return (
        <div className="why-me">
            <div className="text">
            <h4>Why Me?</h4>
                <h2>Together with my team, we can cover the whole video production for you. SEO, thumbnail. Heck, I can even schedule it for you. 🙊</h2>
            </div>
                <div className="cards">
                    <div className="card card-1">
                        <div className="header">
                            <img src={EditSVG} alt="edit" />
                            <h5>1000 scripts written…</h5>
                        </div>
                        <p>Believe me, I’ve seen it all. Finance, crypto, movies, top 10s, true crime - experienced in all YouTube niches</p>
                        <div className="img-wrapper">
                            <img src={ReadPNG} alt="read" />
                        </div>
                    </div>
                    <div className="card card-2">
                        <div className="header">
                            <img src={ZapSVG} alt="zap" />
                        <h5>Adjust to your schedule</h5>
                        </div>
                    <p>2 videos/scripts per week? 3? 5?! Will do… for a bonus, of course.</p>
                        <div className="img-wrapper">
                            <img src={TimePNG} alt="time" />
                        </div>
                    </div>
                    <div className="card card-3">
                        <div className="header">
                            <img src={YoutubeSVG} alt="youtube" />
                        <h5>Top Rated Seller</h5>
                        </div>
                    <p>Top Rated Seller on Upwork in 2022 and Top 1% in scriptwriting niche on Fiverr in 2023</p>
                        <div className="img-wrapper">
                            <img src={WinnerPNG} alt="winner" />
                        </div>
                    </div>
            </div>
        </div>
    );
}
    export default WhyMe;