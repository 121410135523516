import React, {useEffect, useState} from "react"
import { getScripts } from "../../api/getItems"
import './script.scss'
import ScriptRoadmapIMG from '../../assets/roadmap_videos.png'
import CallToAction from "../../components/CTA"

type ScriptCardProps = {
    id: number,
    header: string,
    body: string,
    url: string,
    img: string
}

const colors = [
    'color1', 'color2', 'color3', 'color4', 'color5', 'color6', 'color7'
]

const defaultCards: ScriptCardProps[] = [
    {
        "id": 1,
        "header": "AUTOS AND VEHICLES",
        "body": "Hey everyone, let's dive into a discussion about the G-Wagon, my all-time favorite vehicle. But guess what? It's going electric. We all know that electric is the future, but let's not forget the classic gas-powered G-Wagon, a true legend. Remember the satisfying clunk of the doors? And the roar of the V8 engine when it ignites? Absolutely unbeatable.",
        "url": "https://docs.google.com/document/d/1YbjLZyN9vsjxfz_OR_Ubvo6DTUbeVNvbrQD5kw4gzmM/edit",
        "img": "https://markavrely.pythonanywhere.com/logos/scripts/Untitled.jpeg"
    },
    {
        "id": 2,
        "header": "ANIME",
        "body": "Anime is a limitless source of content in different ways. Many anime series genuinely push the form to its limit. It's been fascinating to see all of the ambitious series that have stepped forward in the past few years. However, there's also been a curious trend to transform it into live-action films.\r\nIt's incredibly risky to make a live-action adaptation of an anime series because anime is so often sophisticated and amazingly stylized. That still hasn't stopped some ambitious filmmakers from trying it, and sometimes, they actually succeed.",
        "url": "https://docs.google.com/document/d/1rsXJeDGtjRTo3wud2MHCBAIBGOk33MpD/edit?usp=sharing&ouid=113208095853183171120&rtpof=true&sd=true",
        "img": "https://markavrely.pythonanywhere.com/logos/scripts/Untitled_2.png"
    },
    {
        "id": 3,
        "header": "PETS",
        "body": "Dogs, our loyal companions and friends, come in an astonishing variety of breeds. While some are familiar faces in neighborhoods worldwide, others remain elusive and rare. Watch until the end and tell me – how many do you recognize?",
        "url": "https://docs.google.com/document/d/19-q3R29c4CgkkEMyXA0J-n_ZG6g5Z-LOoQkROo8NWEU/edit",
        "img": "https://markavrely.pythonanywhere.com/logos/scripts/Untitled_2.jpeg"
    },
    {
        "id": 4,
        "header": "BUSINESS",
        "body": "When you think about owning a business, you might envision renting a space, managing employees, or commuting to work every day. For a busy mom, that scenario might seem overwhelming. But can you overcome the stereotypes and become a successful woman in business? Watch until the end to find out!",
        "url": "https://docs.google.com/document/d/1geodaMJ7HwiLIKbJrGftL7oRRCiJwEMb/edit?rtpof=true&sd=true",
        "img": "https://markavrely.pythonanywhere.com/logos/scripts/Untitled_3.jpeg"
    },
    {
        "id": 5,
        "header": "CELEBRITIES",
        "body": "Just look at these celebrities flirting right in front of us! It's both clumsy and convincing when well-known individuals engage in flirtation on live TV. So many questions arise: Is it natural? Or is it just acting? What will happen next? In almost every case, we are left completely in the dark.",
        "url": "https://docs.google.com/document/d/1FTeRPyFNDMZQlpHYOlL8w50jH8xUODRfGOKMgv6YrRA/edit",
        "img": "https://markavrely.pythonanywhere.com/logos/scripts/Untitled_4.jpeg"
    },
    {
        "id": 6,
        "header": "CRYPTOCURRENCY",
        "body": "Is AI crypto safe? Thanks to advancements like ChatGPT, AI-themed cryptocurrency tokens became increasingly popular in 2023. The AI sector is rapidly advancing, with some experts predicting an astonishing increase in its valuation, potentially reaching nearly $2 trillion by 2030.",
        "url": "https://docs.google.com/document/d/1o2Yq7gAhE9NpdKfl6QKY5lE00Slq2K_9bh-OYJRcjMs/edit?usp=sharing",
        "img": "https://markavrely.pythonanywhere.com/logos/scripts/Untitled_5.jpeg"
    },
    {
        "id": 7,
        "header": "TRAVEL AND EVENTS",
        "body": "Whether you're dreaming of riding the surf, dancing with the rhythm of the jungle, or just sipping on a rum cocktail under a palm tree, Costa Rica has got your ticket to adventure. So pack your bags, and let's dive into the 'Pura Vida' lifestyle as we explore the best places this vibrant country has to offer!",
        "url": "https://docs.google.com/document/d/1bv0_Rl2NAkYnz1QPut7oUcq_xApmzQzkjEcGk3Vu984/edit",
        "img": "https://markavrely.pythonanywhere.com/logos/scripts/Untitled_6.jpeg"
    },
    {
        "id": 8,
        "header": "FILM",
        "body": "Meet Cecilia Kass - she's the type who waits until 3 a.m. to sneak into the kitchen for a guilty snack. She retrieves a pack of sleeping pills from under her mattress and checks the glass of water to ensure her husband Adrian has consumed enough of it. Wow, that's quite a precaution just to eat Twinkies. What can I say - some people simply love eating alone. I love eating alone too; in fact, it's the only way I eat.",
        "url": "https://docs.google.com/document/d/1_S_aYUK0tyOmCwufZgDQYE80x03dx1G67FJC8XFhfcg/edit",
        "img": "https://markavrely.pythonanywhere.com/logos/scripts/Untitled_7.jpeg"
    },
    {
        "id": 9,
        "header": "GAMING",
        "body": "Today, the video game industry is a global powerhouse, valued at $100 billion. Almost two-thirds of American households play video games daily. They are consistently at the forefront of computer technology. But how did it all begin?",
        "url": "https://docs.google.com/document/d/1e6fVzgZ6oMfm0b_jUE1Plit9_MsIEpsx/edit",
        "img": "https://markavrely.pythonanywhere.com/logos/scripts/Untitled_8.jpeg"
    },
    {
        "id": 10,
        "header": "PEOPLE AND BLOGS",
        "body": "So, if you have a small YouTube or TikTok channel that you started to do more of what you love, you might have a little group of followers, and you might slowly realize that it's overwhelming to keep your content consistent and your platform growing with subscribers. The process becomes a chore, making it harder to put as much into it as you once used to. If you're feeling the same way, then you might have a motivation problem.",
        "url": "https://docs.google.com/document/d/1lB3yDn3mEOTsts1lvefgoxq_-sAmyTI7/edit",
        "img": "https://markavrely.pythonanywhere.com/logos/scripts/Untitled_9.jpeg"
    },
    {
        "id": 11,
        "header": "COMEDY",
        "body": "*[Mr. Gotdamnit Sr. takes a wrench and begins explaining the sophistication of the engine. Young Mr. Gotdamnit's eyes sparkle with fascination.]*\r\n\r\nNarrator (V.O.):\r\n\r\n*(Intrigued)*\r\n\r\nIt was in this very garage that Mr. Gotdamnit's love affair with cars began. From that day forward, he couldn't get enough of them... Especially Dodge Hellcats and SRTs.",
        "url": "https://docs.google.com/document/d/1MD0a9oO_zEVRRZVXgvmcg3peTqUupKf4R4yMwzyGggU/edit",
        "img": "https://markavrely.pythonanywhere.com/logos/scripts/Untitled_10.jpeg"
    },
    {
        "id": 12,
        "header": "GADGETS",
        "body": "Welcome to the future, where gadgets aren't just smart - they're ingeniously designed to enhance every aspect of your life! Pocket-sized powerhouses and sleek, futuristic marvels - we're diving into 13 of the coolest gadgets you never knew you needed but won't be able to live without!",
        "url": "https://docs.google.com/document/d/1YhP3AjJSBXtVJWxOTgDu8te77cpf2z6jY1cyixpAnPg/edit",
        "img": "https://markavrely.pythonanywhere.com/logos/scripts/Untitled_11.jpeg"
    },
    {
        "id": 13,
        "header": "ENTERTAINMENT",
        "body": "## **MrBeast:**\r\n\r\nI have millions.\r\n\r\nno flex\r\n\r\n😎\r\n\r\n## **Beluga:**\r\n\r\nYou could make\r\n\r\nmore millions\r\n\r\n## **MrBeast:**\r\n\r\n🤑\r\n\r\nI'm in",
        "url": "https://docs.google.com/document/d/1BJuMy_YSdIwMC2e_JDv2P4MwFTUN8T6KrbzpVKG7crY/edit",
        "img": "https://markavrely.pythonanywhere.com/logos/scripts/Untitled_12.jpeg"
    },
    {
        "id": 14,
        "header": "LUXURY",
        "body": "From art to cars, handbags, watches, jewellery, and rare whiskey bottles, today, we delve into the top 10 luxury collectibles of the past few years. Watch until the end – you might just discover some investment potential for yourself!",
        "url": "https://docs.google.com/document/d/1leaVpvgnb_dhIXl6cJ-n17fvxR5v-gXDJVpcTycHIw0/edit",
        "img": "https://markavrely.pythonanywhere.com/logos/scripts/Untitled_13.jpeg"
    },
    {
        "id": 15,
        "header": "NEWS AND POLITICS",
        "body": "The UK Supreme Court has made it clear - Scotland can't have another vote on independence without permission from the UK government. Scotland's First Minister Humza Yousaf answered - the next general election might act like an independence vote!",
        "url": "https://docs.google.com/document/d/1GfocR3MHROS32AsBveZnQr8su-YwLMDYJStD3spSv1k/edit",
        "img": "https://markavrely.pythonanywhere.com/logos/scripts/Untitled_14.jpeg"
    },
    {
        "id": 16,
        "header": "MAKE MONEY",
        "body": "Have you ever wondered if you could make money while lounging in your pajamas, sipping hot cocoa? Oh yes, many of us have! But if you're tired of the rat race, or if the mere thought of a low-paying job has you daydreaming of dollar bills, then we've got some exciting news for you!",
        "url": "https://docs.google.com/document/d/1NLhhfU_LIRQf9RSJ0kCodTY07WwKp-sVerOpkBW7F0k/edit",
        "img": "https://markavrely.pythonanywhere.com/logos/scripts/Untitled_15.jpeg"
    },
    {
        "id": 17,
        "header": "HOW TO AND STYLE",
        "body": "Earning $24.000 per month on YouTube, without filming any videos whatsoever, without selling goods and doing it from your home in front of a laptop? Sounds like a miracle - or does it?",
        "url": "https://docs.google.com/document/d/1GYqMOpmofe4xKeb4zsm54nqI3s4kDS-8/edit",
        "img": "https://markavrely.pythonanywhere.com/logos/scripts/Untitled_16.jpeg"
    },
    {
        "id": 18,
        "header": "SCIENCE AND TECHNOLOGY",
        "body": "Swarms of robots working in harmony, towering humanoid laborers, digital blueprints, wearable tech that empowers every step - dive in with me as we uncover the groundbreaking technologies and trends shaping the buildings of tomorrow.",
        "url": "https://docs.google.com/document/d/1-QWZ1KoQ3eUYPjQZQ6_fgE-PIMbsUZBthSfZk4Ch1Mo/edit",
        "img": "https://markavrely.pythonanywhere.com/logos/scripts/Untitled_17.jpeg"
    },
    {
        "id": 19,
        "header": "SPORTS",
        "body": "Here's the story: every year, a small village in Russia hosts a wrestling competition similar to Greco-Roman wrestling. Everything was proceeding smoothly until a surprising incident occurred. A grandmother, there to support her beloved grandson, became overly emotional. In a critical moment, she couldn't hold back; she jumped onto the mat and spanked his opponent!",
        "url": "https://docs.google.com/document/d/1rMf-XgpjvDQiug7Z1emsccDO75oXF_9J/edit",
        "img": "https://markavrely.pythonanywhere.com/logos/scripts/Untitled_18.jpeg"
    },
    {
        "id": 20,
        "header": "TRUE CRIME",
        "body": "In the quiet, picturesque town of Skövde, Sweden, a young woman named Lisa Holm lived a life filled with love, laughter, and dreams. Born on February 7, 1998, Lisa, a 17-year-old with a heart of gold, was known for her kindness, love for animals, and joy in sharing music with friends. Her mother, Lena, often spoke of her daughter’s boundless spirit and the bright future that lay ahead.",
        "url": "https://docs.google.com/document/d/1G9U5zzskKh4VCuDJWbQEJPAgThQgpFx2yj9G8h5CCVc/edit",
        "img": "https://markavrely.pythonanywhere.com/logos/scripts/Untitled_19.jpeg"
    }
]

const steps: {header: string, body: string}[] = [
    {
        header: "1. Define Your Goals and Audience",
        body: "Before starting to write, I define what we want to achieve with your video and who your target audience is."
    },
    {
        header: "2. Research Your Topic",
        body: "Gather all necessary information on the topic you’re going to discuss. This involves reading articles, watching other videos, and writing down important points and statistics."
    },
    {
        header: "3. Create an Outline",
        body: "Break down the script into sections or key points. This could be as simple as an introduction, middle, and conclusion for shorter videos, or more detailed, using the BENS system."
    },
    {
        header: "4. Write the Hook",
        body: "The first few seconds of your video will define whether 75% of your audience will watch it."
    },
    {
        header: "5. Write the Body of Your Script",
        body: "Expand the points in the outline, ensuring each section transitions smoothly into the next. I prefer using the “in medias res” concept, which begins without any prior introduction and plunges directly into the action."
    },
    {
        header: "6. Include Calls to Action",
        body: "Prompt viewers to like, subscribe, comment, or follow a link."
    },
    {
        header: "7. Edit and Refine",
        body: "Review your script for clarity, grammar, and length. Make sure it aligns with your initial goals and speaks directly to your intended audience. Read it out loud to check for flow and timing."
    },
    {
        header: "8. Visual Planning",
        body: " Depending on the package you order, I can pair your script with a visual plan. I call this the “advanced” type of script. It outlines what the audience will see when I am delivering specific parts of the script."
    },
    {
        header: "9. Get Your Feedback",
        body: "I’ll send you the script, and you can provide feedback. If needed, I’ll provide revisions, and we’ll finalize it! "
    }
]


const ScriptCard = ({img, header, body, url, color}:ScriptCardProps & {color: string}) => {
    return (
        <div className={`card ${color}`}>
            <a href={url}>
            <img src={img} alt="" />
            <div className="content">

            <h3>{header}</h3>
                <p className="body">{body.slice(0, 150) + '...'}<span>read more</span></p>
            
        </div>
        </a >
        </div>
    )
}
type RoadmapProps = {
    img: string,
    header: string,
    steps: {header: string, body?: string}[]
}

export const Roadmap = ({img, steps, header}: RoadmapProps) => {
    return (
        <div className="roadmap">
            <h2>{header}</h2>
            <div className="steps">
                {steps.map((s, index) => (
                    <div key={index} className={`step ${colors[index % colors.length]}`}>
                        <h3>{s.header}</h3>
                        <p>{s.body}</p>
                    </div>
                ))}
                <img src={img} alt="" />
            
        </div>
        </div>
    )
}

const Script = () => {
    const [cards, setCards] = useState<ScriptCardProps[]>(defaultCards)

    useEffect(() => {
        getScripts().then((data) => {
            if (data) {
                setCards(data)
            }
        })
    }, [])
    return (
        <div className="script">
            <h1>Here’s my work. As you can see, I can cover any YouTube category 📝</h1>
            <div className="scripts">
                {cards.map((c, index) => <ScriptCard key={c.id} color={colors[index % colors.length]} {...c}/>)}
            </div>
            <Roadmap img={ScriptRoadmapIMG} steps={steps} header='How do I write scripts? 🖋' />
            <CallToAction header="Ask for more examples HERE!" btn="Contact me" />
        </div>
    )
}

export default Script;