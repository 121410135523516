import React, { useState, useEffect } from 'react';
import Logo from '../assets/logo.png';
import './style.scss';
import Button from './Button';
import { redirectToWhatsapp } from '../store/cta';
import { Link } from 'react-router-dom';
import { ROUTES } from '../utility/functions';
import { slide as Menu } from 'react-burger-menu'
import './burger.css'

const links = [
    {
        label: 'home',
        route: ROUTES.HOMEPAGE_ROUTE
    },
    {
        label: 'scripts',
        route: ROUTES.SCRIPT
    },
    {
        label: 'videos',
        route: ROUTES.VIDEO
    },
    {
        label: 'youtube managment',
        route: ROUTES.YOUTUBE_MANAGEMENT
    }
]

const Navigation = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isScrolled, setIsScrolled] = useState(false);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const closeMenu = () => {
        setIsMenuOpen(false);
    };

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 20) {
                setIsScrolled(true);
            } else {
                setIsScrolled(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <nav >
            <div className={`desktop-nav ${isScrolled? 'scrolled': '' }`}>
                <Link to={ROUTES.HOMEPAGE_ROUTE}><img className='logo' src={Logo} alt="" /></Link>
                <div className='links'>
                    <ul>
                        {links.map((link, index) => (
                            <li key={index}>
                                <Link to={link.route} onClick={closeMenu}>{link.label}</Link>
                            </li>
                        ))}
                    </ul>
                </div>
                <Button label="Contact Me" className='contactme' onClick={redirectToWhatsapp} />
            <Menu isOpen={isMenuOpen} onStateChange={(state) => setIsMenuOpen(state.isOpen)} right>
                {links.map((link, index) => (
                    <li key={index}>
                        <Link to={link.route} onClick={closeMenu}>{link.label}</Link>
                    </li>
                ))}
            </Menu>
            </div>
        </nav>
    );
}

export default Navigation;
