const root_url = 'https://markavrely.pythonanywhere.com/api/';
const clients_url = root_url + 'clients/';
const videos_url = root_url + 'videos/';
const testimonials_url = root_url + 'testimonials/';
const scripts_url = root_url + 'scripts/';
const price_cards_url = root_url + 'pricecards/';

export {
    clients_url,
    root_url,
    videos_url,
    testimonials_url,
    scripts_url,
    price_cards_url
    
}