import React from 'react';
import Accordion from '../../components/Accordion';

type FAQProps = {
    question: string;
    answer: string;
}

const faqs: FAQProps[] = [
    {
        question: "Do you provide free samples?",
        answer: "Yes. I prefer long-lasting partnerships instead of a quick gig.",
    },
    {
        question: "Do you provide free samples?",
        answer: "I do value my working hours. But I definitely have a script to match your niche, which will surely work as a sample.",
    },
    {
        question: "Do you offer a bulk order?",
        answer: "Sure. In fact, I do offer tempting discounts for bulk orders.",
    },
    {
        question: "What topics do you specialize in?",
        answer: "I have experience in many fields. Health, fitness, finance, business, movies, sci-fi, social media, freelancing, drop shipping, economics, history, sports… Throw a topic and I’ll throw back a script I already wrote.",
    },
    {
        question: "Do you always stick with your format?",
        answer: "You can request any other format. Drop a message to discuss this.",
    },
    {
        question: "I see Advanced script format in your portfolio. What is this?",
        answer: "A detailed script with timestamps and video content suggestions - perfect combo for a faceless YouTube video. Less work for your editor and a better tailored version of your video.",
    }
]
const colors = ['color1', 'color2', 'color3', 'color4', 'color5', 'color6', 'color7'];
const FAQ = () => {
    return (
        <div className="faq">
            <h2>FAQ 🖋</h2>
            <div className="faqs">
                {faqs.map((faq, index) => (
                    <Accordion key={index} question={faq.question} answer={faq.answer} color={colors[index % colors.length]} />
                ))}
            </div>
        </div>
    );
}
    
export default FAQ;