import React, { useState } from 'react';
import Arrow from '../assets/pagination.svg'
interface PaginationProps {
    totalItems: number;
    itemsPerPage: number;
    id: string;
    children: React.ReactNode[];
    
    onPageChange: (page: number) => void;}

const Pagination: React.FC<PaginationProps> = ({ totalItems, itemsPerPage, children, onPageChange, id }) => {
    const scrollToView = (id: string) => {
        document.getElementById(id)?.scrollIntoView({
        });
    };
    const totalPages = Math.ceil(totalItems / itemsPerPage);
    const [currentPage, setCurrentPage] = useState(0);

    const handlePageChange = (page: number) => {
        setCurrentPage(page);
        onPageChange(page);
        scrollToView(id);
    };

    const renderPageDots = () => {
        const pageNumbers = [];

        for (let i = 1; i <= totalPages; i++) {
            pageNumbers.push(
            
                    <div className={`dot ${currentPage === i - 1 ? 'active' : ''}`} onClick={() => handlePageChange(i - 1)} key={i}/>
                
            );
        }

        return pageNumbers;
    };



    const renderPaginationControls = () => {
        return (
            <div className="pagination-controls">
                <img
                    src={Arrow}
                    className={`arrow previous ${currentPage === 0 ? 'disabled' : ''}`}
                    alt="previous"
                    onClick={() => {
                        if (currentPage > 0) {
                            handlePageChange(currentPage - 1);
                        }
                    }}
                />
                {renderPageDots()}
                <img
                    src={Arrow}
                    className={`arrow ${currentPage === totalPages-1 ? 'disabled' : ''}`}
                    alt="next"
                    onClick={() => {
                        if (currentPage < totalPages-1) {
                            handlePageChange(currentPage + 1);
                        }
                    }}
                />
            </div>
        );
    };

    return (
        <div className="pagination" id='pagination'>
            <div className="items">

            {children.map((child, index) => (
                <div key={index} id={`item-${index + 1}`}>
                    {child}
                </div>
            ))}
            </div>
            {renderPaginationControls()}
        </div>
    );
};

export default Pagination;