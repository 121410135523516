import React from 'react';

type ButtonProps = {
    outlined?: boolean;
    // eslint-disable-next-line @typescript-eslint/ban-types
    onClick?: Function;
    children?: React.ReactNode;
    label?: string;
    className?: string;
    type?: "button" | "submit" | "reset";
} & React.HTMLProps<HTMLButtonElement>

const Button: React.FC<ButtonProps> = ({ outlined, children, label, onClick, className, type, ...rest }) => {
    const buttonStyle = outlined ? 'outlined' : 'filled';

    return ( 
        <button {...rest} type={type} className={`button ${className || ""} ${buttonStyle}`} onClick={onClick} >
            {label ? <p>{label}</p>:
                children}
        </button>
    );
};

export default Button;