import React from "react";
import Button from "../../components/Button";
import { redirectToWhatsapp } from "../../store/cta";

const CallToAction2 = () => {

    return (
        <div className='cta bottom' id='contact'>
            <h2>Let’s make your YouTube channel <br />
                a profitable side hustle! 💌</h2>
            <Button label='Contact me' onClick={redirectToWhatsapp} />
        </div>
    );
}
    
export default CallToAction2;