import React, { useEffect, useState } from 'react';
import { getTestimonials } from '../../api/getItems';
import Pagination from '../../components/Pagination';
const colors = ['color9', 'color5', 'color1']
const defaultTestimonials = [
    {
        "id": 1,
        "name": "Elena Akirova",
        "position": "State Adviser",
        "img": "https://markavrely.pythonanywhere.com/logos/testimonial/review_gxA1FQ2.png",
        "text": "Mark, my dear content creator, has significantly boosted my success for the last three years. \r\n\r\nMark's exceptional skills in crafting engaging content, from YouTube scripts and Instagram reels to informative articles and lead magnets, have played a pivotal role in enhancing my online presence and client engagement.\r\n\r\nMark's expertise and creativity have been invaluable in reaching these achievements.",
        "points": [
            {
                "id": 1,
                "title": "top 20 realtors",
                "body": "He helped me secure a spot among the top 20 realtors in Miami (according to PropertyStark)."
            },
            {
                "id": 2,
                "title": "250 million in property sales",
                "body": "Together, we achieved a remarkable milestone of 250 million in property sales"
            },
            {
                "id": 3,
                "title": "100,000  following",
                "body": "grew our social media following to over 100,000 across various social media platforms, including TikTok, Instagram, and YouTube."
            }
        ]
    },
    {
        "id": 2,
        "name": "Richard Politano",
        "position": "Coach",
        "img": "https://markavrely.pythonanywhere.com/logos/testimonial/channels4_profile.jpg",
        "text": "tttt",
        "points": [
            {
                "id": 4,
                "title": "tttt",
                "body": "tttt"
            }
        ]
    }
]

type Testimonial = {
    id: number;
    name: string;
    position: string;
    img: string;
    text: string;
    points: {
        id: number;
        title: string;
        body: string;
    }[];
};

const Testimonials = () => {
    const [testimonials, setTestimonials] = useState<Testimonial[]>(defaultTestimonials);
    const [currentPage, setCurrentPage] = useState(0);
        useEffect(() => {
        getTestimonials().then((data) => {
            if (data) {
                setTestimonials(data);
            }
        });
    }, []);

    const testimonialsPerPage = 1;
    const totalPages = Math.ceil(testimonials.length / testimonialsPerPage);

    const handlePageChange = (page: number) => {
        setCurrentPage(page);
    };

    const renderTestimonials = () => {
        const startIndex = currentPage * testimonialsPerPage;
        const endIndex = startIndex + testimonialsPerPage;
        const currentTestimonials = testimonials.slice(startIndex, endIndex);

        return currentTestimonials.map((testimonial) => (
            <div key={testimonial.id} className="testimonial">
                <div className="review" key={testimonial.id}>
                    <p className="name">{testimonial.name}</p>
                    <p className="position">{testimonial.position}</p>
                    {testimonial.text.split('\n').map((paragraph, index) => (
                        <p className="body" key={index}>{paragraph}</p>
                    ))}
                </div>
                <div className="description">
                    {testimonial.points.map((point, index) => (
                        <div className={`card ${colors[index % colors.length]}`} key={point.id}>
                            <h5>{point.title}</h5>
                            <p>{point.body}</p>
                        </div>
                    ))}
                </div>
                <img src={testimonial.img} alt="" className="review" />
            </div>
        ));
    };

    return (
        <>
            <div className="testimonials" id='testimonials'>
                <Pagination totalItems={totalPages} itemsPerPage={testimonialsPerPage} onPageChange={handlePageChange} id='testimonials'>
                    {renderTestimonials()}
                </Pagination>
            </div>
        </>
    );
};

export default Testimonials;