import './seo.scss'
import React, { useState, useEffect } from 'react'
import Checkmark from '../../assets/Checkmark'
import MedalPNG from '../../assets/medal.png'
import ArrowSVG from '../../assets/arrow-redirect.svg'
import MobileArrow from '../../assets/mobile-arrow.svg'

import { getPriceCards, ScriptCardProps } from '../../api/getItems'
import CallToAction from '../../components/CTA'
import { redirectToWhatsapp } from '../../store/cta'

const cardsColors = [
    '#58A6FF', '#5A61FF', '#8760BC', '#F86F54'
]
const checkColors = [
    '#5A61FF', '#58A6FF', '#8FEBFF', '#FFB6D4', '#8760BC'
]
const defaultCards: ScriptCardProps[] = [
    {
        "id": 1,
        "title": "Creating a YouTube Channel Trailer   🕶️",
        "header": null,
        "price": "$300 over 7 days",
        "body": "A channel trailer is a dynamic, engaging video designed to succinctly communicate the essence of your channel. It can showcase brief, captivating highlights or tell a story about your life, biography, or the narrative behind your channel.",
        "medal_color": "#00000000",
        "priority": 4,
        "items": [
            {
                "id": 1,
                "title": "How to Use Trailer:",
                "bullets": [
                    {
                        "id": 1,
                        "title": "Use the trailer in paid campaigns to effectively gain new subscribers."
                    },
                    {
                        "id": 2,
                        "title": "Getting a subscriber through trailer promotion is generally more cost-effective than through standard video promotions."
                    },
                    {
                        "id": 3,
                        "title": "Viewers who discover your channel through the trailer watch an average of 3 to 4 of your videos. This attracts a new audience and generates organic traffic for your videos in recommendations and on the YouTube homepage."
                    }
                ]
            },
            {
                "id": 2,
                "title": "What We Offer:",
                "bullets": [
                    {
                        "id": 4,
                        "title": "Development of a target audience profile."
                    },
                    {
                        "id": 5,
                        "title": "Crafting the proper structure for your channel trailer."
                    }
                ]
            }
        ]
    },
    {
        "id": 2,
        "title": null,
        "header": "Basic",
        "price": "$700/month",
        "body": "",
        "medal_color": "#F86F5440",
        "priority": 1,
        "items": [
            {
                "id": 3,
                "title": null,
                "bullets": [
                    {
                        "id": 6,
                        "title": "Researching the target audience"
                    },
                    {
                        "id": 7,
                        "title": "Creating and managing a content plan for a month"
                    },
                    {
                        "id": 8,
                        "title": "Designing attractive video thumbnails"
                    },
                    {
                        "id": 9,
                        "title": "Writing SEO-friendly descriptions for videos (including 500 characters, tags, and hashtags)"
                    },
                    {
                        "id": 10,
                        "title": "Providing a monthly report on YouTube Analytics and results and offering recommendations for improvement"
                    },
                    {
                        "id": 11,
                        "title": "Video shooting and/or editing (4-8 per month; additional charges apply)"
                    }
                ]
            }
        ]
    },
    {
        "id": 3,
        "title": null,
        "header": "Advanced",
        "price": "$850/month",
        "body": "",
        "medal_color": "#00000000",
        "priority": 2,
        "items": [
            {
                "id": 4,
                "title": "Includes everything in the \"Basic\" package, plus:",
                "bullets": [
                    {
                        "id": 12,
                        "title": "Creating and managing a content plan for 3 months"
                    },
                    {
                        "id": 13,
                        "title": "Editing, shooting, and posting shorts (10 to 15 per month), either based on filmed material or created from scratch"
                    },
                    {
                        "id": 14,
                        "title": "Launching and managing Google Ads promotions"
                    },
                    {
                        "id": 15,
                        "title": "Manage community posts to announce new videos and live streams and conduct polls"
                    }
                ]
            }
        ]
    },
    {
        "id": 4,
        "title": null,
        "header": "Premium",
        "price": "$1050/month",
        "body": "",
        "medal_color": "#00000000",
        "priority": 3,
        "items": [
            {
                "id": 5,
                "title": "Includes everything from the \"Basic\" and \"Advanced\" packages, plus:",
                "bullets": [
                    {
                        "id": 16,
                        "title": "Creating and managing a content plan for 6 months."
                    },
                    {
                        "id": 17,
                        "title": "Editing, shooting, and posting shorts (10 to 15 per month), either based on filmed material or created from scratch."
                    },
                    {
                        "id": 18,
                        "title": "Organically promoting the channel's trailer, promo videos, or best videos that effectively bring in subscribers."
                    },
                    {
                        "id": 19,
                        "title": "Planned and provided assistance in conducting live streams."
                    },
                    {
                        "id": 20,
                        "title": "Selecting titles and topics aimed at placing videos in the Top 10 search results on YouTube, Google, and Yandex."
                    },
                    {
                        "id": 21,
                        "title": "Networking with bloggers to increase reach."
                    },
                    {
                        "id": 22,
                        "title": "Selecting channels for collaboration and audience exchange to grow viewership."
                    },
                    {
                        "id": 23,
                        "title": "Setting up monetization options, sponsorships, and donations to enhance revenue streams."
                    },
                    {
                        "id": 24,
                        "title": "Consulting on video shooting and editing to improve content quality."
                    }
                ]
            }
        ]
    }
]

const SEO = () => {
    const [cards, setCards] = useState<ScriptCardProps[]>(defaultCards)
    const sortedCards = [...cards].sort((a, b) => a.priority - b.priority);

    useEffect(() => {
        getPriceCards(defaultCards).then((data) => {
            if (data) {
                setCards(data)
            }
        })
    }, [])

    return (
        <div className="seo">
            <h1>Managing your YouTube channel can be frustrating. Let me handle it for you. Here are my YouTube Management packages 👇</h1>
            <div className="seo-content">
                {sortedCards.map((card, index) => (
                    <div key={index} style={{width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                        <h2>{card.title}</h2>
                        <div  className="card" style={{ borderColor: cardsColors[index % cardsColors.length] }}>

                            <div className="bullets">
                                {card.items.map((item, index) => (
                                    <div key={index} className="item">
                                        <h4>{item.title}</h4>
                                        <ul>
                                            {item.bullets.map((bullet, index) => (
                                                <li key={index}>
                                                    <div className="svg-wrapper">

                                                    <Checkmark color={checkColors[index % checkColors.length]} />
                                                    </div>
                                                    <p className="bullet">
                                                    {bullet.title}
                                                    </p>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                ))}
                            </div>
                            <div className="header" style={{ backgroundColor: cardsColors[index % cardsColors.length] }}>
                                <p className="body">{card.body}</p>
                                {card.header &&
                                    <div className='title'>
                                        <h3>&quot;{card.header}&quot;</h3>
                                        <p className="package">Package</p>
                                    </div>}
                                <p className="price">{card.price}</p>
                                <button className="redirect" onClick={() => redirectToWhatsapp()}>
                                    <div className="medal" >
                                        <div className="cover" style={{ backgroundColor: card.medal_color }}></div>
                                     <img src={MedalPNG} alt="" className="photo" />
                                    </div>
                                    <img src={ArrowSVG} alt="" className="arrow" />
                                    <img src={MobileArrow} alt="" className="mobile" />
                                </button>
                            </div>
                            </div>
                        </div>
                    ))}
            </div>
            <CallToAction
                header=' Wanna find out more? Let’s schedule a meeting!'
                btn='Contact me'
            />
        </div>
    )
}

export default SEO;