import React from 'react'
import Button from '../../components/Button'
import MarkIMG from '../../assets/mark1.png'
import { redirectToWhatsapp } from '../../store/cta'

const Initial: React.FC = () => {
    const scrollToView = (e: any) => {
        e.preventDefault();
        // Scroll to a certain element
        document.getElementById("portfolio")?.scrollIntoView({
            behavior: "smooth",
        });
    };
    return (
        <div className='initial'>
            <h1>YouTube Master</h1>
            <h3>Let’s make your channel stand out tall</h3>
            <div className="buttons">
                <Button label="Contact Me" onClick={redirectToWhatsapp} />
                <Button outlined className='myworks' label="My Works" onClick={scrollToView} />
            </div>
            <div className="cards">
                <div className="row">
                    <div className="benefit-card color1"><p>2 revisions  (more are not needed)</p></div>
                    <div className="benefit-card color4"><p>fast delivery</p></div>         

                </div>
                <div className="row row2">
                    <div className="benefit-card color3"><p>5 people job made by one team</p></div>
                    <div className="benefit-card color2"><p>guaranteed ROI</p></div>

</div>

            </div>
            <img className='mark1' src={MarkIMG} alt="" />
        </div>
    )
}

export default Initial
